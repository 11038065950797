.proposals {
  display: flex;
  flex-direction: column;
}

.proposals > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.heading {
  display: inline-block;
  font-size: 2rem;
  margin: 0 !important;
}

.createProposalLink {
  padding: 0.9rem 1.5rem;
  border-radius: 5px;
  border: 0 none;
  font-size: large;
  font-weight: normal;
  cursor: default;
}

.createProposalLink:hover {
  background-color: rgb(104, 104, 104);
}

.proposalLink {
  padding: 1rem;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.markdown h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.markdown h3 {
  font-size: 1.3rem;
}

.markdown img {
  max-width: 100%;
  height: auto;
}
