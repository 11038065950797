.headerWrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 32px;
}

.headerWrapper p {
  margin: 3rem 0;
}

@media (min-width: 992px) {
  .headerWrapper {
    padding: 1rem;
    display: block;
    padding-bottom: 16px;
  }
}

.nounInfoPadding {
  padding-bottom: 1rem;
  font-size: 18px;
}
